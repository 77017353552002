import {
  MenuItem,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import i18n from "../i18n";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import SportsRugbyIcon from '@mui/icons-material/SportsRugby';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import EvStationIcon from '@mui/icons-material/EvStation';

export const getSelectOptions = (
  options,
  discardItems = [],
  emptyText = i18n.t("any")
) => {
  let items = emptyText
    ? [
        <MenuItem key="empty" value="0">
          {emptyText}
        </MenuItem>,
      ]
    : [];
  options.forEach((data, idx) => {
    if (!discardItems.includes(data.value)) {
      items.push(
        <MenuItem key={idx} value={data.value}>
          {data.label}
        </MenuItem>
      );
    }
  });
  return items;
};

export const getRadioButtonOptions = (
  options,
  disabled = false,
  discardItems = []
) => {
  let items = [];
  options.forEach((data, idx) => {
    if (!discardItems.includes(data.value)) {
      items.push(
        <FormControlLabel
          disabled={disabled}
          key={`${data.value}-${idx}`}
          value={data.value}
          control={<Radio />}
          label={data.label}
        />
      );
    }
  });
  return items;
};


export const DefaultPaginationData = {
  page: 0,
  rowsPerPage: 10,
  totalCount: 0,
};
export const getRadioButtonComponent = (
  options,
  props,
  title,
  direction = "row",
  discardItems = []
) => {
  const disabled = props.disabled ? true : false;
  return (
    <FormControl sx={{ textAlign: "left" }} component="fieldset">
      <FormLabel disabled={disabled} color="secondary">
        {title}
      </FormLabel>
      <RadioGroup {...props} row={direction === "row" ? true : false}>
        {getRadioButtonOptions(options, disabled, discardItems)}
      </RadioGroup>
    </FormControl>
  );
};

export const getItemDetails = (options, key = "value") => {
  let items = [];
  options.forEach((data, idx) => {
    items.push(options[key]);
  });
  return items;
};

export const getValueByLabel = (options, label) => {
  for (const idx in options) {
    if (options[idx].label === label) {
      return options[idx].value;
    }
  }
};

export const getLabelByValue = (options, value) => {
  for (const idx in options) {
    if (options[idx].value === value) {
      return options[idx].label;
    }
  }
};

export const getSportIcons = {
  1: <SportsSoccerIcon />,
  2: <SportsFootballIcon />,
  3: <SportsBaseballIcon />,
  4: <SportsBasketballIcon />,
  5: <SportsBasketballIcon />,
  6: <SportsFootballIcon />,
  7: <EvStationIcon />,
  8: <SportsHandballIcon />,
  9: <SportsHockeyIcon />,
  10: <SportsMmaIcon />,
  11: <SportsRugbyIcon />,
  12: <SportsVolleyballIcon />,
};

export const getPopularCountries = [
  {id: 24, name: "Brazil"},
  {id: 49, name: "England"},
  {id: 56, name: "France"},
  {id: 60, name: "Germany"},
  {id: 157, name: "Turkey"},
  {id: 163, name: "USA"},
];


export const getPopularLeagues = [
  {id: 203, name: "Süper Lig"},
  {id: 39, name: "Premier League"},
  {id: 66, name: "Coupe de la Ligue"},
  {id: 78, name: "Bundesliga"},
  {id: 257, name: "US Open Cup"},
  {id: 551, name: "Super Cup"},
];

export const couponStatusMap = {
  0: "Rejected",
  1: "Pending Payment",
  2: "Paid",
  3: "Won (Open)",
  4: "Closed",
  5: "Won (Closed)",
};
export const couponStatusColors = {
  0: "error.main", 
  1: "warning.main", 
  2: "success.main", 
  3: "info.main", 
  4: "secondary.main", 
  5: "primary.main", 
};