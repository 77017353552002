import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { KioskService } from "../../../../api/services";

export default function EditKiosk({
  successCallback,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState("");

  useEffect(() => {
    if (formData) {
      setName(formData.name || ""); 
    }
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedData = {
      name: name, 
    };

    console.log("Submitting form data for kiosk:", updatedData);
    KioskService.updateKiosk(formData.id, updatedData)
      .then((response) => {
        successCallback(); 
        setModalStatus(false); 
        setSnackbarStatus(true); 
        setMessage(t("kiosk-updated-successfully")); 
      })
      .catch((err) => {
        console.error("Failed to update kiosk:", err);
        setSnackbarStatus(true); 
        setMessage(t("failed-to-update-kiosk"));
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label={t("kiosk-name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button onClick={() => setModalStatus(false)}>{t("cancel")}</Button>
          <Button type="submit" color="secondary">
            {t("save")}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
