import { useRoutes, Navigate } from 'react-router-dom';
import { useStore } from './store/Store';
import MainLayout from './layouts/main';
import Auth from './Auth';
import Bet from './pages/Bet';
import WentWrong from './pages/WentWrong';
import { isTokenExpired } from './utils/Util';
import Page404 from './pages/Page404';
import { ListPartitions } from './pages/platform/partitions';
import { AddNewPartition } from './pages/platform/partitions/addPartition';
import ManagePartitions from './pages/platform/partitions/managePartitions/ManagePartitions';
import { ManageCountries, ManageLeagues } from './pages/platform/settings';
import { ManagePartitionCountries, ManagePartitionLeagues } from './pages/platform/partitionSettings';

export default function PlatformRouter() {
  const [store, dispatch] = useStore();
  const platform = JSON.parse(localStorage.getItem("platform"))

  const handleAppRedirect = () => {
    if (isTokenExpired(platform?.expires_at)) {
      (async () => {
        await dispatch({ type: "PLATFORM_LOGOUT" });
      })();
    }

    if (platform !== null && store?.platform?.token !== null) {
        return <MainLayout isAdminLayout={true}/>;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return useRoutes([
    {
      path: "/",
      element: handleAppRedirect(),
      children: [
        // Redirect from "/" to "/partitions/list"
        { path: "/", index: true, element: <Navigate to="/partitions/list" replace /> },
        { path: "/partitions", element: <Navigate to="/partitions/list" /> },
        { path: "/partitions/list", element: <ListPartitions /> },
        { path: "/partitions/manage",  element: <ManagePartitions/> },
        { path: "/partitions/manage/active-sports/:id", element: <ManagePartitions /> },
        { path: "/partitions/manage/active-bookmakers/:id", element: <ManagePartitions /> },
        { path: "/partitions/add-new-partition", element: <AddNewPartition /> },
        { path:   "/reports", element: <Navigate to="/reports/partition-reports" /> },
        { path: "/reports/partition-reports", element: <Bet /> },
        { path: "/settings", element: <Navigate to="/settings/countries" /> },
        { path: "/settings/countries", element: <ManageCountries /> },
        { path: "/settings/leagues/", element: <Navigate to="/settings/leagues/football" /> },
        { path: "/settings/leagues/football", element: <ManageLeagues /> },
        { path: "/partition-settings", element: <Navigate to="/partition-settings/countries" /> },
        { path: "/partition-settings/countries", element: <ManagePartitionCountries /> },
        { path: "/partition-settings/leagues", element: <ManagePartitionLeagues /> },
      ]
    },
    {
      path: "/login",
      element:  (platform === null || platform?.token === null ) ? <Auth type="platform-login" /> : <Navigate to="/"/>,
    },
    { path: "*", element: <Page404 /> },
    { path: "/oops", element: <WentWrong /> },
  ]);
}
