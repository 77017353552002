import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import BaseSnackbar from "../../../components/BaseSnackbar";
import SearchBar from "../../../components/SearchBar";
import { Checkbox, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import ImageLoader from "../../../components/ImageLoader";
import { PartitionSettings, CommonService, PartitionsService } from "../../../api/services";
import React from "react";

export default function ManagePartitionLeagues() {
  const { t } = useTranslation();
  const theme = useTheme();

  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchLeague, setSearchLeague] = useState("");
  const [searchStatus, setSearchStatus] = useState("Any");
  const [updatedLeagues, setUpdatedLeagues] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [partitions, setPartitions] = useState([]);
  const [sports, setSports] = useState([]);
  const [selectedPartition, setSelectedPartition] = useState("");
  const [selectedSport, setSelectedSport] = useState("");
  const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);

  useEffect(() => {
    let result = 0;
    for (const idx in data) {
      const row = data[idx];
      if (
        row &&
        row[TABLE_FIELD_MAPPING["select"].index]?.value?.props?.checked
      ) {
        result++;
      }
    }
    setNumberOfSelectedRows(result);
  }, [data]);

  const handleSelectAll = (event) => {
    setSearchStatus("Any");
    const isChecked = event.target.checked;
    setNumberOfSelectedRows(isChecked ? data.length : 0);

    let tempData = [...data];
    let newUpdatedLeagues = [];

    tempData = tempData.map((row) => {
      const leagueID = row[TABLE_FIELD_MAPPING["id"].index].value;
      const currentIsEnabled =
        row[TABLE_FIELD_MAPPING["is_enabled"].index].value.props.children ===
        "True";

      if (currentIsEnabled !== isChecked) {
        newUpdatedLeagues.push({
          league_id: leagueID,
          is_enabled: isChecked,
        });
      }

      row[TABLE_FIELD_MAPPING["select"].index] = {
        ...row[TABLE_FIELD_MAPPING["select"].index],
        value: (
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleRowSelect(e, leagueID)}
          />
        ),
      };

      row[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...row[TABLE_FIELD_MAPPING["is_enabled"].index],
        value: (
          <Typography
            sx={{
              color: isChecked
                ? theme.palette.success.main
                : theme.palette.warning.main,
            }}
          >
            {isChecked ? "True" : "False"}
          </Typography>
        ),
      };

      return row;
    });

    setData(tempData);
    setFilteredData(filterLeagues(tempData));
    setUpdatedLeagues(newUpdatedLeagues);
  };

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", index: 0, noRender: true },
    logo: { key: "logo", index: 1 },
    name: { key: "name", index: 2 },
    type: { key: "type", index: 3 },
    country_name: { key: "country_name", index: 4 },
    is_enabled: { key: "enabled", index: 5, align: "center" },
    select: { key: "select", index: 6, align: "center" },
  };
  
  const TABLE_HEAD = [
    { key: "logo", label: t("Logo") },
    { key: "name", label: t("League") },
    { key: "type", label: t("Type") },
    { key: "country_name", label: t("country") },
    { key: "enabled", label: t("enabled"), align: "center" },
    {
      key: "select",
      label: (
        <Checkbox
          checked={data.length > 0 && numberOfSelectedRows === data.length}
          indeterminate={
            numberOfSelectedRows > 0 && numberOfSelectedRows < data.length
          }
          onChange={handleSelectAll}
        />
      ),
      align: "center",
    },
  ];
  useEffect(() => {
    fetchPartitions();
    fetchSports();
  }, []);

  useEffect(() => {
    if (selectedPartition && selectedSport) {
      fetchPartitionLeagues();
    }
  }, [selectedPartition, selectedSport]);

  const fetchPartitions = async () => {
    try {
      const response = await PartitionsService.listPartitions();
      setPartitions(response.data.items || []);
    } catch (error) {
      setMessage(t("failed-to-fetch-partitions"));
      setSnackbarStatus(true);
    }
  };

  const fetchSports = async () => {
    try {
      const response = await CommonService.listSports();
      setSports(response.data || []);
    } catch (error) {
      setMessage(t("failed-to-fetch-sports"));
      setSnackbarStatus(true);
    }
  };

  const fetchPartitionLeagues = () => {
    setLoadingData(true);
    PartitionSettings.listPartitionLeagues(selectedPartition, selectedSport)
      .then((response) => {
        if (response.status === 200) {
          const items = response.data
            .sort((a, b) => b.is_enabled - a.is_enabled) 
            .map((item) => {
              const row = new Array(TABLE_HEAD.length).fill({});
  
              row[TABLE_FIELD_MAPPING["id"].index] = {
                ...TABLE_FIELD_MAPPING["id"],
                value: item?.football_league.id || "Unkown",
              };
  
              row[TABLE_FIELD_MAPPING["logo"].index] = {
                ...TABLE_FIELD_MAPPING["logo"],
                value: item.football_league.logo ? (
                  <ImageLoader
                    src={item.football_league?.logo}
                    alt={`${item.football_league.name} logo`}
                    width={30}
                    height={30}
                  />
                ) : (
                  <PublicTwoToneIcon
                    fontSize="small"
                    sx={{ mr: 0.5, color: theme.palette.text.secondary }}
                  />
                ),
              };
  
              row[TABLE_FIELD_MAPPING["name"].index] = {
                ...TABLE_FIELD_MAPPING["name"],
                value: item.football_league?.name,
              };
  
              row[TABLE_FIELD_MAPPING["type"].index] = {
                ...TABLE_FIELD_MAPPING["type"],
                value: item.football_league?.type,
              };
  
              row[TABLE_FIELD_MAPPING["country_name"].index] = {
                ...TABLE_FIELD_MAPPING["country_name"],
                value: item.football_league.country?.name || t("Unknown"),
              };
  
              row[TABLE_FIELD_MAPPING["is_enabled"].index] = {
                ...TABLE_FIELD_MAPPING["is_enabled"],
                value: (
                  <Typography
                    sx={{
                      color: item.is_enabled
                        ? theme.palette.success.main
                        : theme.palette.warning.main,
                    }}
                  >
                    {item.is_enabled ? "True" : "False"}
                  </Typography>
                ),
              };
  
              row[TABLE_FIELD_MAPPING["select"].index] = {
                ...TABLE_FIELD_MAPPING["select"],
                value: (
                  <Checkbox
                    checked={!!item.is_enabled}
                    onChange={(event) => handleRowSelect(event, item.football_league?.id || "Unknown")}
                  />
                ),
              };
  
              return row;
            });
          setData(items);
          setFilteredData(items);
        } else {
          throw new Error("Failed to fetch leagues");
        }
      })
      .catch((error) => {
        setMessage(t("failed-to-fetch-leagues"));
        console.error(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const handleRowSelect = (event, leagueID) => {
    setNumberOfSelectedRows(
      event.target.checked ? numberOfSelectedRows + 1 : numberOfSelectedRows - 1
    );
    setFilteredData((prevData) => {
      const tempData = [...prevData];

      const index = tempData.findIndex(
        (item) => item[TABLE_FIELD_MAPPING["id"].index]?.value === leagueID
      );

      const selectedItem = tempData[index];
      const isSelected =
        selectedItem[TABLE_FIELD_MAPPING["select"].index]?.value?.props
          ?.checked;
      const isEnabled =
        selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index]?.value?.props
          ?.children === "True";

      selectedItem[TABLE_FIELD_MAPPING["select"].index] = {
        ...selectedItem[TABLE_FIELD_MAPPING["select"].index],
        value: (
          <Checkbox
            checked={!isSelected}
            onChange={(event) => handleRowSelect(event, leagueID)}
          />
        ),
      };

      selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index],
        value: (
          <Typography
            sx={{
              color: !isEnabled
                ? theme.palette.success.main
                : theme.palette.warning.main,
            }}
          >
            {!isEnabled ? "True" : "False"}
          </Typography>
        ),
      };

      setUpdatedLeagues((prevLeagues) => {
        const indexInUpdated = prevLeagues.findIndex(
          (item) => item.league_id === leagueID
        );
        if (indexInUpdated === -1) {
          return [
            ...prevLeagues,
            { league_id: leagueID, is_enabled: !isEnabled },
          ];
        } else {
          const newLeauges = [...prevLeagues];
          newLeauges.splice(indexInUpdated, 1);
          return newLeauges;
        }
      });

      return tempData;
    });
  };

  const handleSave = () => {
    setSubmitting(true);

    const payload = {
      mappings: updatedLeagues.map((league) => ({
        football_league_id: league.league_id,
        basketball_league_id: league.basketball_league_id,
        sport_id: selectedSport, 
        is_enabled: league.is_enabled,
      })),
    };

    PartitionSettings.bulkUpdatePartitionLeagues(selectedPartition, payload)
      .then(() => {
        setMessage(t("updated-successfully"));
        setSnackbarStatus(true);
        setUpdatedLeagues([]);
      })
      .catch(() => {
        setMessage(t("failed-to-update-leagues"));
        setSnackbarStatus(true);
      })
      .finally(() => setSubmitting(false));
  };
  const filterLeagues = (data) => {
    return data.filter((item) => {
      const leagueName = item[2].value.toLowerCase();
      const countryName = item[4].value.toLowerCase();
      const isEnabled = item[5].value.props.children === "True";

      const nameMatch =
        !searchLeague || leagueName.includes(searchLeague.toLowerCase()) || countryName.includes(searchLeague.toLowerCase());
      const statusMatch =
        searchStatus === "Any" ||
        (searchStatus === "Enabled" && isEnabled) ||
        (searchStatus === "Disabled" && !isEnabled);

      return nameMatch && statusMatch;
    });
  };

  useEffect(() => {
    setFilteredData(filterLeagues(data));
  }, [searchLeague, searchStatus]);

  return (
    <>
      <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
      <TableFilterContainer
       sx={{
        display: "flex",
        alignItems: "center",
        gap: "3em",
        mt: "1em",
      }}
      >
        <SearchBar
          sx={{ ml: "2em" }}
          searchQuery={searchLeague}
          handleSearchChange={(e) => setSearchLeague(e.target.value)}
        />
        <FormControl sx={{ width: "15%" }}>
          <InputLabel>{t("Select Partition")}</InputLabel>
          <Select value={selectedPartition} onChange={(e) => setSelectedPartition(e.target.value)}>
            {partitions.map((partition) => (
              <MenuItem key={partition.id} value={partition.id}>
                {partition.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "15%" }}>
          <InputLabel>{t("Select Sport")}</InputLabel>
          <Select value={selectedSport} onChange={(e) => setSelectedSport(e.target.value)}>
            {sports.map((sport) => (
              <MenuItem key={sport.id} value={sport.id}>
                {sport.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "10%" }}>
          <InputLabel>{t("status")}</InputLabel>
          <Select value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
            <MenuItem value="Any">Any</MenuItem>
            <MenuItem value="Enabled">Enabled</MenuItem>
            <MenuItem value="Disabled">Disabled</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          type="submit"
          loading={submitting}
          disabled={updatedLeagues.length === 0}
          sx={{
            bgcolor:
              updatedLeagues.length > 0
                ? theme.palette.success.dark
                : theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.success.main_light,
            },
            ml: "auto",
            mr: "4em",
          }}
          variant="contained"
          size="large"
          onClick={() => handleSave()}
          startIcon={<SaveIcon sx={{ height: "1.2rem", mr: -0.3 }} />}
        >
          {t("save-all")}
        </LoadingButton>
      </TableFilterContainer>

      {!selectedPartition || !selectedSport ? (
      <Typography
        sx={{
          mt: 6,
          textAlign: "center",
          color: theme.palette.text.secondary,
        }}
        variant="h6"
      >
        {t("please-select-partition-and-sport-to-see-leagues")}
      </Typography>
    ) : data.length === 0 ? (
      <Typography
        sx={{
          mt: 6,
          textAlign: "center",
          color: theme.palette.text.secondary,
        }}
        variant="h6"
      >
        {t("no-leagues-found-for-selected-sport-or-partition")}
      </Typography>
    ) : (
      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        loadingData={loadingData}
        pagination={{}}
        smallRow={true}
      />
    )}
  </>
  );
}
