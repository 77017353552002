import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TableFilterContainer, BaseTable } from "../../../components/table";
import SearchBar from "../../../components/SearchBar";
import { DistributorsService } from "../../../api/services";
import { DefaultPaginationData } from "../../../constants";

export default function ListStores() {
  const { t } = useTranslation();
  const [storeData, setStoreData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const TABLE_HEAD = [
    { key: "store_id", label: t("Store ID") },
    { key: "name", label: t("Store Name") },
    { key: "created_at", label: t("Created At") },
  ];

  const TABLE_FIELD_MAPPING = {
    store_id: { key: "store_id", index: 0 },
    name: { key: "name", index: 1 },
    created_at: { key: "created_at", index: 2 },
  };

  const fetchStores = () => {
    setLoading(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    DistributorsService.listDistributorStore(params)
      .then((response) => {
        if (response.status === 200) {
          const items = response.data.stores.map((store) => {
            const item = new Array(TABLE_HEAD.length).fill({});
            Object.keys(TABLE_FIELD_MAPPING).forEach((key) => {
              const value =
                key === "created_at"
                  ? new Date(store[key]).toLocaleDateString()
                  : store[key];
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value || "",
              };
            });
            return item;
          });
          setStoreData(items);
          setTotalCount(response.data.total || items.length);
        } else {
          throw new Error(t("Failed to fetch stores"));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchStores();
  }, [paginationData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) =>
      ["name"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      })
    );
  };

  const filteredData = filterData(storeData, searchQuery);

  return (
    <>
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
      </TableFilterContainer>
      <Box sx={{ mt: 4 }}>
        <BaseTable
          head={TABLE_HEAD}
          data={filteredData}
          pagination={{
            paginationData: { ...paginationData, totalCount },
            setPaginationData,
          }}
          loadingData={loading}
        />
      </Box>
    </>
  );
}
