import api from "../Api";
import urls from "../Urls";

export const listKiosk = (params) => {
    return api.get(urls.list_kiosk, {params: params});
}

export const createKiosk = (payload) => {
    return api.post(urls.create_kiosk, payload)
}


export const updateKiosk = (kiosk_id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_kiosk(kiosk_id), payload, {params})
}

export const deleteKiosk = (kiosk_id) => {
    return api.delete(urls.delete_kiosk(kiosk_id));
};
