import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useTranslation } from "react-i18next";
import { OddsService } from '../../api/services';

const CouponSummary = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { couponData } = location.state || {};
  const [showSuccess, setShowSuccess] = React.useState(false);

  const couponTypeLabel = couponData?.coupon_type === 1 ? 'Single' : 'Multiple';

  const handlePrint = async () => {
    try {
      let placed_bets = couponData.placed_bets.map((item) => ({
        fixture_id: item.fixture.fixture_id,
        odd_id: item.odd_id,
        bet_amount: item.bet_amount
      }));
      let payload = {
        "placed_bets": placed_bets,
        "coupon_type": couponData.coupon_type
      }
      const response = await OddsService.createCoupon(payload); 
      setShowSuccess(true); 
      setTimeout(() => {
        setShowSuccess(false); 
        navigate('/ki'); 
      }, 5000);
    } catch (error) {
      console.error("Error adding bet coupon:", error.message);
    }
  };

  const calculateBetReturn = (bet) => {
    return bet.bet_amount * parseFloat(bet.odd || '0.00');
  };

  const calculateTotalReturn = () => {
    if (couponData?.coupon_type === 1) {
      // Single bet
      return couponData.placed_bets.reduce(
        (total, bet) => total + calculateBetReturn(bet),
        0
      );
    } else {
      // Multiple bet
      const combinedOdds = couponData.placed_bets.reduce(
        (product, bet) => product * parseFloat(bet.odd || '0.00'),
        1
      );
      return combinedOdds * (couponData.placed_bets[0]?.bet_amount || 0);
    }
  };

  return (
    <Box position="relative">
      <Snackbar
        open={showSuccess}
        autoHideDuration={5000} 
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="success"
          sx={{
            width: "100%",
            fontSize: "1.3rem", 
            fontWeight: "bold",
            padding: "18px", 
            backgroundColor: "#FEBF17", 
            color: "#fff", 
            textAlign: "center", 
          }}
        >
          {t("coupon-printed-successfully-back-to-kiosk")}
        </Alert>
      </Snackbar>
      <Paper
        sx={{
          maxWidth: 400,
          backgroundColor: "#fff",
          boxShadow: 1,
          margin: "auto",
          mt: 4,
          p: 2,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", pb: 2 }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: "#2e7d32",
            }}
          >
            {t("coupon-summary")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: "medium",
              color: "text.secondary",
              mt: 1,
            }}
          >
            {couponTypeLabel} Bet
          </Typography>
        </Box>

        <Box sx={{ p: 2 }}>
          {couponData?.placed_bets.map((bet, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  {bet.fixture.home_team} vs {bet.fixture.away_team}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#2e7d32",
                  }}
                >
                  {bet.odd}
                </Typography>
              </Box>
              {/* Only display if is single bets */}
              {couponData?.coupon_type === 1 && (
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      fontSize: "0.8rem",
                      color: "#2e7d32",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Stake")}: ₺{bet.bet_amount.toFixed(2)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      color: "#2e7d32",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Return")}: ₺{calculateBetReturn(bet).toFixed(2)}
                  </Typography>
                </>
              )}
              <Divider sx={{ mt: 1 }} />
            </Box>
          ))}

          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography sx={{ fontSize: "0.9rem" }}>
                {" "}
                {t("Total Stake")}
              </Typography>
              <Typography sx={{ fontSize: "0.9rem" }}>
                ₺
                {couponData?.placed_bets
                  .reduce((acc, bet) => acc + bet.bet_amount, 0)
                  .toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: "#2e7d32",
                fontWeight: "bold",
              }}
            >
              <Typography sx={{ fontSize: "0.9rem" }}>
                {" "}
                {t("Total Payout")}
              </Typography>
              <Typography sx={{ fontSize: "0.9rem" }}>
                ₺{calculateTotalReturn().toFixed(2)}
              </Typography>
            </Box>
          </Box>

          <Button
            variant="contained"
            fullWidth
            color="accent"
            sx={{ my: 2 }}
            startIcon={<PrintIcon />}
            onClick={handlePrint}
          >
            {t("Print")}
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            startIcon={<KeyboardReturnIcon />}
            onClick={() => navigate("/ki")}
          >
            {t("Back-to-kiosk")}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CouponSummary;
