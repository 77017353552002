import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, Box, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { DefaultPaginationData } from "../../../constants";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { OddsService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import { couponStatusColors, couponStatusMap } from "../../../constants";

export default function ListBets() {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [couponStatusFilter, setCouponStatusFilter] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const TABLE_HEAD = [
    { key: "id", label: t("bet-id") },
    { key: "coupon_status", label: t("coupon-status") },
    { key: "bet_amount", label: t("bet-amount") },
    { key: "expected_payout", label: t("expected-payout") },
  ];

  const fetchBets = async () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      ...(couponStatusFilter && { coupon_status: couponStatusFilter }),
    };

    try {
      const response = await OddsService.listCoupons(params);
      const bets = response.data.items;

      const items = bets.map((item) => {
        const placedBet = item.placed_bets?.[0];
        return [
          { key: `id-${item.id}`, value: item.id },
          {
            key: `coupon_status-${item.id}`,
            value: <Typography sx={{ color: couponStatusColors[item.coupon_status] || "text.primary" }}>
            {couponStatusMap[item.coupon_status] || "Unknown Status"}
          </Typography>,
          },
          { key: `bet_amount-${item.id}`, value: placedBet?.bet_amount || 0 },
          { key: `expected_payout-${item.id}`, value: item.total_payout || 0 },
        ];
      });

      setData(items);
      setTotalCount(response.data.total);
    } catch (err) {
      setMessage(t("failed-to-get-bets"));
      setSnackbarStatus(true);
      console.error(err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchBets();
  }, [paginationData, couponStatusFilter]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    const lowerQuery = query.toLowerCase();
    const statusId = Object.keys(couponStatusMap).find(
      (key) => couponStatusMap[key].toLowerCase() === lowerQuery
    );

    return data.filter((row) => {
      return (
        ["id", "coupon_status"].some((key, idx) => {
          const cellValue = row[idx]?.value.toString().toLowerCase() || "";
          return cellValue.includes(lowerQuery);
        }) ||
        (statusId && row[1].value === couponStatusMap[statusId])
      );
    });
  };

  const clearFilters = () => {
    setCouponStatusFilter("");
    setSearchQuery("");
  };

  const filteredData = filterData(data, searchQuery);

  return (
    <>
      <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />

      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          alignItems: "center",
          gap: "2rem"
        }}
      >
        <SearchBar searchQuery={searchQuery} handleSearchChange={handleSearchChange} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Select
            value={couponStatusFilter}
            onChange={(event) => setCouponStatusFilter(event.target.value)}
            displayEmpty
            sx={{ mt: 1, mr: 2 }}
          >
            <MenuItem value="">{t("coupon-status")}</MenuItem>
            {Object.entries(couponStatusMap).map(([key, label]) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <IconButton onClick={clearFilters} sx={{ mt: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        pagination={{
          paginationData: { ...paginationData, totalCount },
          setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
