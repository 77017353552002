import React, { useState, useEffect, useCallback } from "react";
import { Box, Select, MenuItem, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { OddsService } from "../../../api/services";
import { couponStatusMap, couponStatusColors } from "../../../constants";

export default function Reports() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
  });
  const [reportData, setReportData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [timeRange, setTimeRange] = useState("this-week");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });
  const [totalBetsCount, setTotalBetsCount] = useState(0);

  const REPORT_TABLE_HEAD = [
    { key: "status", label: t("coupon-status") },
    { key: "totalBets", label: t("total-bets") },
    { key: "totalAmount", label: t("total-amount") },
    { key: "totalPayout", label: t("total-payout") },
    { key: "totalSingle", label: t("total-single-bet") },
    { key: "totalMultiple", label: t("total-multiple-bet") },
  ];

  const fetchCoupons = useCallback(async () => {
    setLoadingData(true);
  
    let startDate, endDate;
  
    const today = new Date();
    const dayOfWeek = today.getDay(); 
    const startOfWeek = new Date(today); 
    startOfWeek.setDate(today.getDate() - dayOfWeek);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
  
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
    switch (timeRange) {
      case "this-week":
        startDate = startOfWeek;
        endDate = endOfWeek;
        break;
      case "last-week":
        startDate = new Date(startOfWeek);
        startDate.setDate(startOfWeek.getDate() - 7);
        endDate = new Date(endOfWeek);
        endDate.setDate(endOfWeek.getDate() - 7);
        break;
      case "this-month":
        startDate = startOfMonth;
        endDate = endOfMonth;
        break;
      case "last-month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "custom":
        if (!filters.startDate || !filters.endDate) {
          endDate = new Date();
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 7);
        } else {
          startDate = new Date(filters.startDate);
          endDate = new Date(filters.endDate);
        }
        break;
      default:
        startDate = new Date();
        startDate.setDate(today.getDate() - 7);
        endDate = today;
    }
  
    const params = {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    };
  
    try {
      let allCoupons = [];
      let page = 1;
      let totalPages = 1;
  
      while (page <= totalPages) {
        const response = await OddsService.listCoupons({
          ...params,
          page,
          size: 100,
        });
        const coupons = response.data.items;
        allCoupons = [...allCoupons, ...coupons];
  
        const totalItems = response.data.total;
        totalPages = Math.ceil(totalItems / 100);
        page += 1;
      }
  
      const totalsByStatus = {};
      let totalBets = 0;
  
      allCoupons.forEach((item) => {
        const statusKey = item.coupon_status;
        const status = couponStatusMap[statusKey] || "Unknown Status";
        const couponTypeLabel = item.coupon_type === 1 ? "Single" : "Multiple";
  
        if (!totalsByStatus[status]) {
          totalsByStatus[status] = {
            totalBets: 0,
            totalAmount: 0,
            totalPayout: 0,
            totalSingle: 0,
            totalMultiple: 0,
          };
        }
  
        totalsByStatus[status].totalBets += 1;
        totalBets += 1;
  
        const betAmount = item.placed_bets
          ? item.placed_bets.reduce((sum, bet) => sum + bet.bet_amount, 0)
          : 0;
        totalsByStatus[status].totalAmount += betAmount;
        totalsByStatus[status].totalPayout += item.total_payout || 0;
  
        if (couponTypeLabel === "Single") {
          totalsByStatus[status].totalSingle += 1;
        } else {
          totalsByStatus[status].totalMultiple += 1;
        }
      });
  
      const reportData = Object.keys(totalsByStatus).map((status) => ({
        status,
        color: couponStatusColors[
          Object.keys(couponStatusMap).find(
            (key) => couponStatusMap[key] === status
          )
        ],
        totalBets: totalsByStatus[status].totalBets,
        totalAmount: totalsByStatus[status].totalAmount.toFixed(2),
        totalPayout: totalsByStatus[status].totalPayout.toFixed(2),
        totalSingle: totalsByStatus[status].totalSingle,
        totalMultiple: totalsByStatus[status].totalMultiple,
      }));
  
      setReportData(reportData);
      setTotalBetsCount(totalBets);
    } catch (error) {
      setSnackbarState({ open: true, message: t("failed-to-get-coupons") });
      console.error(error);
    } finally {
      setLoadingData(false);
    }
  }, [filters, timeRange, t]);
  

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  return (
    <>
      <BaseSnackbar
        open={snackbarState.open}
        message={snackbarState.message}
        onClose={() => setSnackbarState({ open: false, message: "" })}
      />

      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, mr: 2 }}>
          <Select
            sx={{ mt: 1, width: "10rem" }}
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
          >
            <MenuItem value="this-week">{t("this-week")}</MenuItem>
            <MenuItem value="last-week">{t("last-week")}</MenuItem>
            <MenuItem value="this-month">{t("this-month")}</MenuItem>
            <MenuItem value="last-month">{t("last-month")}</MenuItem>
            <MenuItem value="custom">{t("custom")}</MenuItem>
          </Select>
          {timeRange === "custom" && (
            <>
              <TextField
                type="date"
                value={filters.startDate}
                onChange={(e) =>
                  setFilters({ ...filters, startDate: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                type="date"
                value={filters.endDate}
                onChange={(e) =>
                  setFilters({ ...filters, endDate: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </>
          )}
        </Box>
      </TableFilterContainer>
        <Typography sx={{mt:2, fontSize:"1.3rem", color:"#4C5768"}}>
          {t("total-bets")}: {totalBetsCount}
        </Typography>
    
      <BaseTable
        head={REPORT_TABLE_HEAD}
        data={reportData.map((item) => [
          {
            key: "status",
            value: (
              <Typography sx={{ color: item.color, fontWeight: "bold" }}>
                {item.status}
              </Typography>
            ),
          },
          { key: "totalBets", value: item.totalBets },
          { key: "totalAmount", value: item.totalAmount },
          { key: "totalPayout", value: item.totalPayout },
          { key: "totalSingle", value: item.totalSingle },
          { key: "totalMultiple", value: item.totalMultiple },
        ])}
        loadingData={loadingData}
        pagination={{}}
      />
    </>
  );
}
