import React, { useState, useEffect, useCallback } from "react";
import { Tooltip, IconButton, Typography, Box, Button, Select, MenuItem, TextField, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { DefaultPaginationData } from "../../../constants";
import { rowArrayToObject } from "../../../utils/Util";
import BaseModal from "../../../components/BaseModal";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import SearchBar from "../../../components/SearchBar";
import { OddsService } from "../../../api/services";
import { couponStatusColors, couponStatusMap } from "../../../constants";

export default function ListCoupons() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useState({ open: false, message: "" });
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    couponStatus: "",
    startDate: "",
    endDate: "",
  });
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  const TABLE_HEAD = [
    { key: "id", label: t("coupon-id") },
    { key: "coupon_status", label: t("coupon-status") },
    { key: "bet_amount", label: t("bet-amount") },
    { key: "total_payout", label: t("expected-payout") },
    { key: "created_at", label: t("created-date") },
    { key: "actions", label: t("actions"), align: "center" },
  ];
  
  const TABLE_FIELD_MAPPING = {
    id: { key: "id", index: 0 },
    coupon_status: { key: "coupon_status", index: 1 },
    bet_amount: { key: "bet_amount", index: 2 },
    total_payout: { key: "total_payout", index: 3 },
    created_at: { key: "created_at", index: 4 },
  };

  const fetchCoupons = useCallback(async () => {
    setLoadingData(true);
    const { couponStatus, startDate, endDate } = filters;
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      ...(couponStatus && { coupon_status: couponStatus }),
      ...(startDate && { start_date: new Date(startDate).toISOString() }),
      ...(endDate && { end_date: new Date(endDate).toISOString() }),
    };

    try {
      const response = await OddsService.listCoupons(params);
      const coupons = response.data.items.map((item) => {
        const row = new Array(TABLE_HEAD.length - 1).fill({});
        Object.keys(TABLE_FIELD_MAPPING).forEach((key) => {
          let value;
          switch (key) {
            case "coupon_status":
              value = (
                <Typography sx={{ color: couponStatusColors[item.coupon_status] || "text.primary" }}>
                  {couponStatusMap[item.coupon_status] || "Unknown Status"}
                </Typography>
              );
              break;
            case "bet_amount":
              value = item.placed_bets
                ? item.placed_bets.reduce((sum, bet) => sum + bet.bet_amount, 0).toFixed(2)
                : "0.00";
              break;
            case "total_payout":
              value = item.total_payout ? item.total_payout.toFixed(2) : "0.00";
              break;
            case "created_at":
              value = new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              }).format(new Date(item.created_at));
              break;
            default:
              value = item[key] || "";
          }
          row[TABLE_FIELD_MAPPING[key].index] = { ...TABLE_FIELD_MAPPING[key], value };
        });
        return { key: item.id, row, bets: item.placed_bets || [] };
      });

      setData(coupons);
      setTotalCount(response.data.total);
    } catch (error) {
      setSnackbarState({ open: true, message: t("failed-to-get-coupons") });
      console.error(error);
    } finally {
      setLoadingData(false);
    }
  }, [paginationData, filters, t]);

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  const filterData = (query) =>
    data.filter(({ row }) =>
      ["id", "coupon_status"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      })
    );

    const clearFilters = () => {
      setFilters({
        couponStatus: "",
        startDate: "",
        endDate: "",
      });
      setSearchQuery("");
      fetchCoupons(); 
    };

  const handleModalToggle = (modalType, index = undefined) => {
    if (index !== undefined) {
      const row = rowArrayToObject(data[index].row, TABLE_FIELD_MAPPING);
      setSelectedCoupon({ ...row, bets: data[index].bets });
  
      if (modalType === "edit") {
        const currentStatusKey = Object.keys(couponStatusMap).find(
          (key) => couponStatusMap[key] === row.coupon_status.props.children
        );
  
        setNewStatus(currentStatusKey || ""); 
      }
    }
  
    if (modalType === "view") setOpenViewModal(!openViewModal);
    if (modalType === "edit") setOpenEditModal(!openEditModal);
  };

  const handleEditStatus = async () => {
    try {
      await OddsService.updateCouponStatus(selectedCoupon.id, {
        new_status: parseInt(newStatus, 10),
      });
      setSnackbarState({
        open: true,
        message: t("status-updated-successfully"),
      });
      setOpenEditModal(false);
      fetchCoupons();
    } catch (error) {
      console.error(error);
      setSnackbarState({
        open: true,
        message: t("failed-to-update-status"),
      });
    }
  };

  const getActionItems = (index) => (
    <Box sx={{ display: "flex", justifyContent: "center", minWidth: "120px" }}>
      <Tooltip title={t("view-details")}>
        <IconButton color="primary" onClick={() => handleModalToggle("view", index)}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("edit-status")}>
        <IconButton color="secondary" onClick={() => handleModalToggle("edit", index)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <BaseSnackbar
        open={snackbarState.open}
        message={snackbarState.message}
        onClose={() => setSnackbarState({ open: false, message: "" })}
      />
      <BaseModal
        title={t("coupon-details")}
        open={openViewModal}
        setOpen={setOpenViewModal}
        sx={{
          width: "40%",
          height: "auto",
          overflow: "none",
        }}
        children={
          <Box>
            <Typography
              variant="h6"
              sx={{ marginBottom: 2, textAlign: "center" }}
            >
              {t("Fixtures and Bets")}
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            {selectedCoupon.bets?.length > 0 ? (
              <>
                {selectedCoupon.bets.map((bet, idx) => (
                  <Box key={idx} sx={{ paddingBottom: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 1,
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {`${bet.fixture.home_team_name} - ${bet.fixture.away_team_name}`}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        {`${bet.odd.value} - ${bet.odd.odd}`}
                      </Typography>
                    </Box>
                    {idx < selectedCoupon.bets.length - 1 && <Divider />}
                  </Box>
                ))}

                <Box sx={{ marginTop: 2 }}>
                  <Divider />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", marginTop: 2 }}
                  >
                    {t("bet-amount")}:{" "}
                    {selectedCoupon.bet_amount || "0.00"}
                  </Typography>
                  <Typography variant="body2">
                    {t("expected-payout")}:{" "}
                    {selectedCoupon.total_payout || "0.00"}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography
                variant="body1"
                sx={{ textAlign: "center", color: "text.secondary" }}
              >
                {t("No bets found")}
              </Typography>
            )}
          </Box>
        }
      />

      <BaseModal
        title={t("change-coupon-status")}
        open={openEditModal}
        setOpen={setOpenEditModal}
        children={
          <>
            <Select
              fullWidth
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              sx={{ mt: 2 }}
            >
              {Object.entries(couponStatusMap).map(([key, label]) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button onClick={() => setOpenEditModal(false)}>
                {t("cancel")}
              </Button>
              <Button
                onClick={handleEditStatus}
                variant="contained"
                color="primary"
              >
                {t("save")}
              </Button>
            </Box>
          </>
        }
      />

      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          onSearchChange={(e) => setSearchQuery(e.target.value)}
        />
        <Box sx={{ display: "flex", gap: 2, mr: 2 }}>
          <Select
            sx={{ mt: 1 }}
            value={filters.couponStatus}
            onChange={(e) =>
              setFilters({ ...filters, couponStatus: e.target.value })
            }
            displayEmpty
          >
            <MenuItem value="">{t("coupon-status")}</MenuItem>
            {Object.entries(couponStatusMap).map(([key, label]) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            type="date"
            value={filters.startDate}
            onChange={(e) =>
              setFilters({ ...filters, startDate: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            value={filters.endDate}
            onChange={(e) =>
              setFilters({ ...filters, endDate: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
          />
          <IconButton onClick={clearFilters} sx={{ mt: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={filterData(searchQuery).map(({ row }) => row)}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount },
          setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
