import api from "../Api"
import urls from "../Urls";

export const listPartitionCountries = (partition_id, sport_id, params) => {
    return api.get(urls.list_partition_countries(partition_id, sport_id), {params: params})
}

export const bulkUpdatePartitionCountries = (partition_id, payload, params) => {
    params = params ? params : {proceed : false};
    return api.put(urls.bulk_update_partition_countries(partition_id), payload, {params})
}

export const listPartitionLeagues = (partition_id, sport_id, params) => {
    return api.get(urls.list_partition_leagues(partition_id,sport_id), {params: params})
}

export const bulkUpdatePartitionLeagues = (partition_id, payload, params) => {
    params = params ? params : {proceed : false};
    return api.put(urls.bulk_update_partition_leagues(partition_id), payload, {params})
}
